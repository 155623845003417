import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8383/api";

const instance = axios.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/json" },
});

// Tambahkan interceptor untuk menyisipkan token
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Ambil token dari localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Tambahkan interceptor untuk menangani respons
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Sembunyikan URL backend dari konsol browser
    if (error.response) {
      if (error.response.status === 404) {
        console.error("Error: Barcode not found!");
      } else if (error.response.status === 401) {
        console.error("Error: Unauthorized. Please log in again.");
      } else {
        console.error("Error: An unexpected error occurred.");
      }
    } else {
      console.error("Error: Unable to connect to the server.");
    }

    return Promise.reject({
      message: error.response?.data?.message || "An unexpected error occurred.",
      status: error.response?.status || 500,
    });
  }
);

export default instance;
