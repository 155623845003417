import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import Home from "./pages/Main";
import Login from "./pages/Login";
import BarcodeResult from "./pages/BarcodeResult";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token")
  );

  const handleLogin = () => setIsAuthenticated(true);

  // Fungsi logout: Hapus token, set status ke "false", redirect ke /login
  const handleLogout = () => {
    localStorage.removeItem("token"); // Hapus token
    setIsAuthenticated(false); // Perbarui status autentikasi
  };

  const RedirectWithCode = () => {
    const { code } = useParams(); // Ambil parameter ':code'
    localStorage.setItem("barcode", code); // Simpan barcode ke local storage
    return <Navigate to="/" />; // Redirect ke Home
  };

  return (
    <Router>
      <Routes>
        {/* Halaman Login */}
        <Route path="/login" element={<Login onLogin={handleLogin} />} />

        {/* Halaman Home */}
        <Route
          path="/"
          element={isAuthenticated ? <Home /> : <Navigate to="/login" />}
        />

        {/* Jika token valid, redirect dengan barcode */}
        <Route
          path="/barcodes/:code"
          element={isAuthenticated ? <RedirectWithCode /> : <BarcodeResult />}
        />

        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Home onLogout={handleLogout} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
