import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../utils/api";
import "./BarcodeResult.css";
import { FaWhatsapp, FaInstagram } from "react-icons/fa"; // Impor ikon dari React Icons

const BarcodeResult = ({ isTokenValid }) => {
  const { code } = useParams(); // Ambil nilai ':code'
  const navigate = useNavigate();
  const [result, setResult] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      // Simpan nilai barcode di local storage dan redirect
      localStorage.setItem("barcode", code);
      setError("");
      navigate("/");
    } else {
      // Tanpa token: Proses pengecekan barcode
      const fetchBarcode = async () => {
        try {
          const response = await axios.get(`/barcodes/${code}`);
          if (response.status === 204) {
            setResult("❌❌❌ Code not found! ❌❌❌");
            setError("Barcode does not exist in the database.");
          } else {
            setResult(response.data.message);
            setError("");
          }
        } catch (err) {
          if (err.response?.status === 404) {
            setResult("❌❌❌ Code not found! ❌❌❌"); // Tampilkan pesan jika status 404
          } else {
            setResult("🚑🚑 Error checking barcode"); // Tampilkan pesan error umum
          }
          setError(err.response?.data?.message || "An error occurred");
        } finally {
          setIsLoading(false);
        }
      };

      fetchBarcode();
    }
  }, [code, navigate]);

  return (
    <div className="barcode-result">
      {isLoading ? (
        <div className="loading-icon">
          <div className="spinner"></div> {/* Icon loading animasi */}
          <p>Loading...</p>
        </div>
      ) : (
        <>
          {result && <p className="success">{result}</p>}
          {error && <p className="error">{error}</p>}
          {/* <p>{result}</p> */}
          {!isTokenValid && (
            <>
              <hr className="divider" /> {/* Garis pembatas */}
              <div className="card-footer">
                <p>Asoka Kafe</p>
                <div className="social-links">
                  <a
                    href="https://wa.me/6285174470523"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                  >
                    <FaWhatsapp className="icon whatsapp-icon" />
                    <span>WhatsApp</span>
                  </a>
                  <span className="separator"> || </span>
                  <a
                    href="https://www.instagram.com/asoka.kafe/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                  >
                    <FaInstagram className="icon instagram-icon" />
                    <span>Instagram</span>
                  </a>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default BarcodeResult;
