import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot
import App from "./App";

const container = document.getElementById("root"); // Ambil elemen root
const root = createRoot(container); // Buat root baru

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
