import React, { useState, useEffect } from "react";
import "./Main.css";
import axios from "../utils/api";
import { useNavigate } from "react-router-dom";

const Home = ({ onLogout }) => {
  const navigate = useNavigate(); // Untuk navigasi ke halaman lain
  const [barcode, setBarcode] = useState(""); // State untuk input field
  const [result, setResult] = useState(null); // Hasil dari backend
  const [isLoading, setIsLoading] = useState(false); // Status loading
  const [isChecked, setIsChecked] = useState(false); // Status apakah barcode sudah dicek
  const [isTokenValid, setIsTokenValid] = useState(
    !!localStorage.getItem("token")
  ); // Cek token
  const [isBlinking, setIsBlinking] = useState(false); // State untuk animasi blink

  // Ambil nilai barcode dari local storage saat komponen dimuat
  useEffect(() => {
    if (!isTokenValid) {
      console.log("Token not valid or not available"); // Debugging token
    }

    const savedBarcode = localStorage.getItem("barcode");
    if (savedBarcode) {
      setBarcode(savedBarcode);
      localStorage.removeItem("barcode"); // Hapus barcode dari local storage setelah digunakan
    }
  }, [isTokenValid]);

  useEffect(() => {
    if (result === "Barcode already used") {
      setIsBlinking(true);
      const timeout = setTimeout(() => setIsBlinking(false), 6000); // Hentikan blink setelah 6 detik
      return () => clearTimeout(timeout);
    }
  }, [result]);

  // Handle perubahan pada input field
  const handleInputChange = (e) => {
    setBarcode(e.target.value);
    setIsChecked(false); // Reset state isChecked ketika input berubah
    setResult(null); // Reset hasil ketika input berubah
  };

  // Proses barcode ke backend untuk pengecekan
  const handleCheckBarcode = async () => {
    if (!barcode) return;
    setIsLoading(true);
    setResult(null);
    try {
      const response = await axios.get(`/barcodes/${barcode}`);
      if (response.status === 204) {
        setResult("❌❌❌ Code not found! ❌❌❌");
      } else {
        setResult(response.data.message); // Simpan hasil dari backend
      }
      setIsChecked(true); // Tandai bahwa barcode telah dicek
    } catch (err) {
      if (err.response?.status === 404) {
        setResult("❌❌❌ Code not found! ❌❌❌"); // Tampilkan pesan jika status 404
      } else {
        setResult("🚑🚑 Error checking barcode"); // Tampilkan pesan error umum
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Proses barcode ke backend untuk menandai sebagai digunakan
  const handleMarkAsUsed = async () => {
    if (!barcode) return;
    setIsLoading(true);
    setResult(null);
    try {
      const response = await axios.post(`/barcodes/${barcode}`);
      setResult(response.data.message); // Simpan hasil dari backend
    } catch (err) {
      setResult("Error marking barcode as used");
    } finally {
      setIsLoading(false);
    }
  };

  // Menghapus input field dan hasil
  const handleClear = () => {
    setBarcode("");
    setResult(null);
    setIsChecked(false); // Reset state isChecked
  };

  const handleLogoutClick = () => {
    localStorage.removeItem("token"); // Hapus token dari localStorage
    navigate("/login"); // Redirect ke halaman login
  };

  const isResultVisible = !!result; // Kondisi card barcodeResult

  return (
    <div className="main-container">
      {!isTokenValid && result && (
        <div className="card card-error">
          <h2>Result</h2>
          <p>{result}</p>
        </div>
      )}
      <div className="card">
        <h1>ASOKA COUPON CODE CHECK!</h1>
        <div className="input-container">
          <input
            type="text"
            id="input-barcode"
            placeholder="Enter Barcode"
            value={barcode}
            onChange={handleInputChange} // User tetap dapat mengetik manual
          />
          <div className="button-container">
            {/* Button Clear */}
            <button
              onClick={handleClear}
              className="clear-btn"
              disabled={!barcode}
            >
              Clear
            </button>

            {/* Button Check Barcode */}
            {!isChecked && !isResultVisible && (
              <button
                onClick={handleCheckBarcode}
                className="check-btn"
                disabled={!barcode}
              >
                Check Barcode
              </button>
            )}

            {/* Button Mark as Used */}
            {isChecked && isResultVisible && (
              <button
                onClick={handleMarkAsUsed}
                className={`mark-btn ${
                  result === "Barcode already used" ||
                  result === "Barcode marked as used" ||
                  result === "❌❌❌ Code not found! ❌❌❌"
                    ? "disabled"
                    : ""
                }`}
                disabled={
                  result === "Barcode already used" ||
                  result === "Barcode marked as used" ||
                  result === "❌❌❌ Code not found! ❌❌❌"
                }
              >
                Mark as Used
              </button>
            )}
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="loading">
          <p>Loading...</p>
        </div>
      )}
      {isTokenValid && result && (
        <div className={`card ${isBlinking ? "blinking" : ""}`}>
          <h2>Barcode Result:</h2>
          <p>{result}</p>
        </div>
      )}

      {!isTokenValid && result && (
        <div className="barcode-result">
          <h2>Barcode Result</h2>
          <p>{result}</p>
          <hr className="divider" /> {/* Garis pembatas */}
          <div className="card-footer">
            <p>Asoka Kafe</p>
            <div className="social-links">
              <a
                href="https://wa.me/6285174470523"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon whatsapp-icon">WhatsApp</i>
              </a>
              <a
                href="https://www.instagram.com/asoka.kafe/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon instagram-icon">Instagram</i>
              </a>
            </div>
          </div>
        </div>
      )}

      {/* Footer dengan tombol Logout */}
      <footer className="footer">
        <button onClick={handleLogoutClick} className="logout-btn">
          Logout
        </button>
      </footer>
    </div>
  );
};

export default Home;
